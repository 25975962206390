<template>
  <v-container>
    <v-row justify="center">
      <v-col lg="8">
        <EventLogWidget :isLoading="isLoading" />
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="sass" scoped></style>
<script>
// @ is an alias to /src
import EventLogWidget from "@/components/EventLogWidget.vue";

export default {
  name: "EventsComponent",
  components: {
    EventLogWidget
  },
  data: () => ({
    isLoading: false
  }),
  async mounted() {}
};
</script>
