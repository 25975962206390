import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import GlobalMixin from "./mixins/GlobalMixin";

import VueDraggableResizable from "vue-draggable-resizable";

import "./mixins/GlobalMixin";

import { Auth0Plugin } from "./auth";

const app = createApp(App);

app.component("vue-draggable-resizable", VueDraggableResizable);

app.mixin(GlobalMixin);

app.use(router);
app.use(store);
app.use(vuetify);

app.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

app.mount("#app");
