<template>
  <div class="app-toast">
    <v-snackbar
      :model-value="show"
      @update:model-value="show = $event"
      :color="toast.type || 'info'"
      multi-line
      location="top"
      :timeout="timeout"
    >
      <div class="ml-4">
        <v-row>
          {{ toast.message }}
          <v-btn
            v-if="hasDetails && !showDetails"
            text
            x-small
            @click="showDetailsHandler"
          >
            Show details
          </v-btn>
        </v-row>

        <v-row v-if="hasDetails && showDetails">
          {{ toast.details }}
        </v-row>
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import eventBus from "@/lib/eventBus";

export default {
  name: "ToastComponent",
  data() {
    return {
      show: false,
      showDetails: false,
      timeout: 6000,
      toast: {
        type: null,
        message: false,
        details: null
      },
      close: true
    };
  },
  computed: {
    hasDetails() {
      return this.toast.details != null && this.toast.details != "";
    }
  },
  created() {
    let me = this;
    eventBus.off("show-toast");
    eventBus.on("show-toast", payload => {
      me.showToast(payload);
    });
  },

  methods: {
    showToast({ type, message, details, close = true }) {
      let me = this;
      me.toast.type = type;
      me.toast.message = message;
      me.toast.details = details;
      me.close = close;
      me.show = true;
      me.showDetails = false;
    },
    showDetailsHandler() {
      let me = this;
      me.showDetails = true;
    }
  }
};
</script>
