//import Vue from "vue";
import Vuex from "vuex";

import app from "./modules/app";
import units from "./modules/units";
import status from "./modules/status";
import events from "./modules/events";
import situation from "./modules/situation";
import localStorage from "./modules/localStorage";
import alerts from "./modules/alerts";
import missions from "./modules/missions";
import resources from "./modules/resources";

// TODO CHECK Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    units,
    status,
    events,
    situation,
    localStorage,
    alerts,
    missions,
    resources
  }
});
