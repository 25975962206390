import _ from "lodash";
import { reactive } from "vue";
import { sureClone } from "../../lib/DataUtils";

const set = (target, key, value) => {
  target[key] = value;
};

const del = (target, key) => {
  delete target[key];
};

export default ({ state = {}, getters = {}, mutations = {}, actions = {} }) => {
  return {
    namespaced: true,
    getters,
    actions,
    state: reactive(
      _.merge(state, {
        _initial: sureClone(state)
      })
    ),
    mutations: _.merge(mutations, {
      set(state, [key, value]) {
        if (key.match(/\.|\[/)) {
          let parentPath = key
            .split(".")
            .slice(0, -1)
            .join(".");
          let childKey = key.split(".").pop();
          set(_.get(state, parentPath), childKey, value);
        } else {
          set(state, key, value);
        }
      },

      unset(state, key) {
        if (key.match(/\.|\[/)) {
          let parentPath = key
            .split(".")
            .slice(0, -1)
            .join(".");
          let childKey = key.split(".").pop();
          del(_.get(state, parentPath), childKey);
        } else {
          del(state, key);
        }
      },

      empty(state, key) {
        if (key.match(/\.|\[/)) {
          let parentPath = key
            .split(".")
            .slice(0, -1)
            .join(".");
          let childKey = key.split(".").pop();
          set(_.get(state, parentPath), childKey, null);
        } else {
          state[key] = null;
        }
      },

      mapSet(state, [path, id, payload]) {
        set(_.get(state, path), id, payload);
      },

      mapUnset(state, [path, id]) {
        del(_.get(state, path), id);
      },

      reset_default_state(state) {
        let cleanState = sureClone(state._initial);
        _.each(cleanState, (value, key) => {
          set(state, key, value);
        });
      }
    })
  };
};
