// src/vuetify.js
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";

const vuetify = createVuetify({
  defaults: {
    VToolbar: {
      density: "compact"
    },
    VToolbarTitle: {
      style: "font-size: 1.0rem;",
      class: "font-weight-medium text-uppercase"
    },
    VCard: {
      elevation: 5
    }
  },
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: "#09656d",
          secondary: "#11bece",
          accent: "#14D684",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFC107"
        }
      }
    }
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi
    }
  }
});

export default vuetify;
