<template>
  <v-card>
    <v-toolbar
      density="compact"
      color="primary"
      class="pl-5 text-uppercase font-weight-medium"
    >
      <v-icon class="mr-2">mdi-account</v-icon>
      Yksiköt
      <v-spacer></v-spacer>
      <UnitCreator />
    </v-toolbar>

    <UnitEditor :active="editDialog" @closed="editDialog = false" />
    <v-data-table
      :items="units"
      :headers="headers"
      class="units-table"
      item-key="id"
      :loading="isLoading"
      density="compact"
    >
      <template v-slot:item.type="{ item }">
        {{ itemTypeDescription(item.type) }}
      </template>
      <template v-slot:item.identifier="{ item }">
        {{ item.type === 1 ? item.identifier : "" }}
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-icon color="primary" class="mr-2" @click="editUnit(item.id)">
            mdi-pencil
          </v-icon>
          <v-icon color="primary" @click="deleteUnit(item.id)">
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>
<style lang="sass"></style>

<script>
import UnitCreator from "@/views/UnitCreator.vue";
import UnitEditor from "@/views/UnitEditor.vue";

export default {
  props: {
    isLoading: Boolean
  },
  components: {
    UnitCreator,
    UnitEditor
  },
  data: () => ({
    selectedStatus: null,
    editDialog: false,
    headers: [
      { title: "Tunnus", value: "name", sortable: false },
      { title: "Kuvaus", value: "description", sortable: false },
      { title: "Tyyppi", value: "type", sortable: false },
      { title: "VIRVE-tilaajanumero", value: "identifier", sortable: false },
      { title: "", value: "actions", sortable: false, filterable: false }
    ]
  }),
  computed: {
    units() {
      return this.$store.getters["units/units"];
    },
    statuses() {
      return this.$store.getters["status/status"];
    }
  },
  methods: {
    itemTypeDescription(itemType) {
      switch (itemType) {
        case 1:
          return "VIRVE";
        case 2:
          return "Virtuaalinen";
      }
    },
    async editUnit(id) {
      let me = this;
      me.$debug("editUnit", id);
      await me.$store.dispatch("units/loadUnitToEditor", id);
      me.editDialog = true;
    },
    async deleteUnit(id) {
      let me = this;
      try {
        let unit = me.units.find(u => u.id === id);
        await me.$confirm(
          "Haluatko varmasti poistaa yksikön " + unit.name + " ?",
          "Vahvista poisto",
          "Poista",
          "Peruuta"
        );
        await me.$store.dispatch("units/deleteUnit", id);
        me.$toast({
          type: "success",
          message: `Yksikkö ` + unit.name + ` poistettu`
        });
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
      }
    }
  }
};
</script>
