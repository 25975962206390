<template>
  <StatusEditor :active="editDialog" @closed="editDialog = false" />
  <v-card>
    <v-toolbar
      density="compact"
      color="primary"
      class="pl-5 text-uppercase font-weight-medium"
    >
      <v-icon size="small" class="mr-2">mdi-format-list-checks</v-icon>
      Tilatiedot
      <v-spacer></v-spacer>
      <StatusCreator />
    </v-toolbar>

    <v-data-table
      density="compact"
      :items="status"
      :headers="headers"
      item-key="id"
      :loading="isLoading"
    >
      <template v-slot:item.name="{ item }">
        <v-chip
          density="compact"
          :text-color="item.colorText"
          :color="item.colorBackground"
          >{{ item.name }}</v-chip
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-icon color="primary" class="mr-2" @click="editStatus(item.id)">
            mdi-pencil
          </v-icon>
          <v-icon
            :disabled="item.systemStatus"
            color="primary"
            @click="deleteStatus(item.id)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>
<style lang="sass" scoped></style>
<script>
// TODO: Chechk functionality and styling with other cases when we get those
import StatusCreator from "@/views/StatusCreator.vue";
import StatusEditor from "@/views/StatusEditor.vue";
export default {
  data() {
    return {
      isLoading: false,
      editDialog: false,
      headers: [
        { title: "Tila", value: "name", sortable: false },
        { title: "Numeerinen arvo", value: "value", sortable: false },
        { title: "", value: "actions", sortable: false, filterable: false }
      ]
    };
  },
  components: {
    StatusCreator,
    StatusEditor
  },
  computed: {
    status() {
      return this.$store.getters["status/status"];
    }
  },
  methods: {
    async editStatus(id) {
      let me = this;
      await me.$store.dispatch("status/loadStatusToEditor", id);
      me.editDialog = true;
    },
    async deleteStatus(id) {
      let me = this;
      try {
        let status = me.status.find(s => s.id === id);
        await me.$confirm(
          "Haluatko varmasti poistaa tilan " + status.name + " ?",
          "Vahvista poisto",
          "Poista",
          "Peruuta"
        );
        await me.$store.dispatch("status/deleteStatus", id);
        me.$toast({
          type: "success",
          message: `Tila ` + status.name + ` poistettu`
        });
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
      }
    }
  },
  async mounted() {}
};
</script>
