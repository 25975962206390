<template>
  <base-dialog
    v-model="dialog"
    title="Uusi tehtävä"
    icon="mdi-bell-plus"
    :valid-form="validForm"
    :is-loading="isLoading"
    @cancel="handleCancel"
    @save="createMission"
  >
    <template #content>
      <v-form v-model="validForm">
        <v-row>
          <v-col cols="12">
            <v-select
              label="Yksiköt"
              v-model="units"
              multiple
              :items="availableUnits"
              item-title="name"
              item-value="id"
              placeholder="Valitse yksiköt"
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="code"
              label="Tehtäväkoodi*"
              placeholder="esim. 704A"
              outlined
              required
              :rules="$rules.required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="address"
              label="Osoite*"
              placeholder="esim. Hätäkuja 112"
              outlined
              required
              :rules="$rules.required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="municipality"
              label="Paikkakunta*"
              placeholder="esim. Kotka"
              outlined
              required
              :rules="$rules.required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="details"
              label="Lisätiedot"
              placeholder="esim. Ovikoodi 1234"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <small class="text-caption text-medium-emphasis"
        >*pakollinen kenttä</small
      >
    </template>
  </base-dialog>

  <v-btn
    v-if="inAppBar"
    color="white"
    small
    variant="outlined"
    class="mr-3"
    @click="newMission"
  >
    <v-icon size="small" left class="mr-1">mdi-bell-plus</v-icon>
    Uusi tehtävä
  </v-btn>

  <v-btn v-else color="white" small text @click="newMission">
    <v-icon size="small">mdi-plus</v-icon>
    Uusi tehtävä
  </v-btn>
</template>

<script>
import BaseDialog from "./BaseDialog.vue";
import FormMixin from "@/mixins/FormMixin";

export default {
  components: { BaseDialog },
  props: {
    inAppBar: Boolean
  },
  mixins: [FormMixin],
  data() {
    return {
      dialog: false,
      validForm: false,
      isLoading: false
    };
  },
  computed: {
    code: {
      get() {
        return this.$store.state.missions.editor.code;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.code", value]);
      }
    },
    municipality: {
      get() {
        return this.$store.state.missions.editor.municipality;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.municipality", value]);
      }
    },
    address: {
      get() {
        return this.$store.state.missions.editor.address;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.address", value]);
      }
    },
    details: {
      get() {
        return this.$store.state.missions.editor.details;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.details", value]);
      }
    },
    units: {
      get() {
        return this.$store.state.missions.editor.units;
      },
      set(value) {
        this.$store.commit("missions/set", ["editor.units", value]);
      }
    },
    availableUnits: {
      get() {
        return this.$store.getters["units/units"];
      }
    }
  },
  methods: {
    async newMission() {
      let me = this;
      await me.$store.commit("missions/newMissionToEditor", me.$store.state);
      me.dialog = true;
    },
    async createMission() {
      let me = this;
      try {
        me.isLoading = true;
        let mission = await me.$store.dispatch("missions/createMission");
        me.$toast({
          type: "success",
          message: `Uusi tehtävä '${mission.code} - ${mission.address}' on lisätty`
        });
        me.isLoading = false;
        me.dialog = false;
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
        me.isLoading = false;
      }
    },
    handleCancel() {
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
/* Lisää tarvittaessa mukautettuja tyylejä */
</style>
