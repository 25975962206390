<template>
  <v-card>
    <v-toolbar density="compact" color="primary">
      <v-toolbar-title>
        <v-icon size="small" class="mr-2">mdi-message-flash-outline</v-icon>
        VIRVE-viestin lähetys
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <UnitCreator />
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-form v-model="validForm">
          <v-row>
            <v-col>
              <v-select
                label="Vastaanottajat"
                v-model="units"
                multiple
                :items="availableUnits"
                item-title="name"
                item-value="id"
                filled
                :rules="$rules.atLeastOneSelection"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="message"
                label="Viesti"
                filled
                rows="4"
                :rules="$rules.required"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                color="primary"
                v-model="isUnitAlert"
                label="Yksikköhälytys"
                hide-details
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-actions class="ma-3">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="cancelAlert">
        Peruuta
      </v-btn>
      <v-btn
        color="primary"
        variant="tonal"
        :disabled="!validForm"
        :loading="isLoading"
        @click="createAlert"
      >
        Lähetä
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="sass" scoped>
.status-select {
  font-size: 14px;
}
.status-select.v-text-field>.v-input__control>.v-input__slot:before {
  border-style: none;
}
.status-select.v-text-field>.v-input__control>.v-input__slot:after {
  border-style: none;
}
.v-select__selections input {
  display: none;
}
</style>
<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  props: {
    inModal: Boolean
  },
  data: () => ({
    validForm: false,
    isLoading: false
  }),
  computed: {
    availableUnits: {
      get() {
        return this.$store.getters["units/alarmableUnits"];
      }
    },
    units: {
      get() {
        return this.$store.state.alerts.editor.recipients;
      },
      set(value) {
        this.$store.commit("alerts/set", ["editor.recipients", value]);
      }
    },
    message: {
      get() {
        return this.$store.state.alerts.editor.message;
      },
      set(value) {
        this.$store.commit("alerts/set", ["editor.message", value]);
      }
    },
    isUnitAlert: {
      get() {
        return this.$store.state.alerts.editor.isUnitAlert;
      },
      set(value) {
        this.$store.commit("alerts/set", ["editor.isUnitAlert", value]);
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit("is-valid", value);
    }
  },
  methods: {
    async cancelAlert() {
      let me = this;
      await me.$store.commit("alerts/newAlertToEditor");
    },
    async createAlert() {
      let me = this;
      try {
        me.isLoading = true;
        await me.$store.dispatch("alerts/createAlert");
        me.$toast({
          type: "success",
          message: `Viesti on lähetetty.`
        });
        me.isLoading = false;
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
        me.isLoading = false;
      }
    }
  }
};
</script>
