import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";
import { sureClone } from "@/lib/DataUtils";
import ModuleBase from "../common/ModuleBase";
import moment from "moment";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:EventsStore`);

const DEFAULT_EDITOR = {
  id: null,
  dateTime: null,
  unit: [],
  event: "Muistiinpano",
  description: null
};

const set = (target, key, value) => {
  target[key] = value;
};

export default ModuleBase({
  state: {
    events: {},
    editor: sureClone(DEFAULT_EDITOR)
  },

  getters: {
    events({ events }) {
      let values = _.orderBy(_.values(events), ["dateTime"], ["desc"]);
      return values;
    }
  },

  mutations: {
    clearEvents(state) {
      state.events = [];
    },
    addReceivedEvent(state, event) {
      set(state.events, event.id, event);
    },
    newEventToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    }
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let events = await ApiClient.get(`/eventlog`);
      debug("load", events);
      commit("set", ["events", _.keyBy(events, "id")]);
    },
    async createEvent({ state }) {
      let event = sureClone(state.editor);
      debug("createEvent", event.unit);
      _.unset(event, "id");
      _.set(event, "dateTime", moment());
      _.set(event, "unit", event.unit.join(", "));
      debug("createEvent", event);
      let response = await ApiClient.post("/eventlog", event);
      debug("createEvent - response", response);
      //commit("addEvent", response);
      return response;
    }
  }
});
