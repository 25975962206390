<template>
  <div class="app-confirm">
    <v-dialog :model-value="show" max-width="400" absolute>
      <v-card>
        <v-toolbar color="primary" density="compact">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text v-if="message" class="mt-3">
          {{ message }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="confirmCancel">
            {{ textCancelButton }}
          </v-btn>
          <v-btn color="primary" variant="tonal" @click="confirmOk">
            {{ textOkButton }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="sass" scoped></style>
<script>
import eventBus from "@/lib/eventBus";

export default {
  name: "AppConfirm",

  data() {
    return {
      show: null,
      title: null,
      message: null,
      textOkButton: null,
      textCancelButton: null
    };
  },

  created() {
    let me = this;
    eventBus.off("show-confirm");
    eventBus.on("show-confirm", payload => {
      me.showConfirm(payload);
    });
  },

  methods: {
    confirmCancel() {
      let me = this;
      eventBus.emit("confirm.cancel");
      me.reset();
    },

    confirmOk() {
      let me = this;
      eventBus.emit("confirm.ok");
      me.reset();
    },

    reset() {
      let me = this;
      me.show = false;
      me.title = null;
      me.message = null;
    },

    showConfirm({ message, title = null, textOkButton, textCancelButton }) {
      let me = this;
      me.$debug("showConfirm", message, title);
      me.title = title;
      me.message = message;
      me.textOkButton = textOkButton;
      me.textCancelButton = textCancelButton;
      me.show = true;
    }
  }
};
</script>
