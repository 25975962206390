import ModuleBase from "../common/ModuleBase";

export default ModuleBase({
  state: {
    signalRState: "Unknown"
  },
  getters: {
    signalRState({ signalRState }) {
      return signalRState;
    }
  },
  mutations: {},
  actions: {}
});
