<template>
  <v-container
    fluid
    class="d-flex justify-center align-center"
    style="height: 100vh;"
  >
    <v-row>
      <v-col class="d-flex flex-column justify-center align-center">
        <v-img
          class="home-page-logo"
          alt="KRISTA logo"
          src="../assets/krista_logo_pos.png"
          :width="400"
        />
        <v-btn
          v-if="!$auth.state.isAuthenticated && !$auth.state.loading"
          color="primary"
          large
          @click="login"
        >
          Kirjaudu palveluun
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="sass" scoped>
.home-page-logo {
  margin-bottom: 50px
}
</style>
<script>
export default {
  name: "HomeComponent",
  methods: {
    // Log the user in
    login() {
      console.log("login");
      this.$auth.loginWithRedirect();
    }
  }
};
</script>
