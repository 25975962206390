<template>
  <v-dialog :model-value="modelValue" persistent :max-width="maxWidth">
    <v-card>
      <v-toolbar :color="toolbarColor">
        <v-toolbar-title>
          <v-icon class="mr-2" v-if="icon">{{ icon }}</v-icon>
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <slot name="content"></slot>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="ma-3">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$emit('cancel')">
          Peruuta
        </v-btn>
        <v-btn
          color="primary"
          variant="tonal"
          :disabled="!validForm"
          :loading="isLoading"
          @click="$emit('save')"
        >
          Tallenna
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
    title: {
      type: String,
      default: "Dialog"
    },
    icon: {
      type: String,
      default: null
    },
    toolbarColor: {
      type: String,
      default: "primary"
    },
    maxWidth: {
      type: String,
      default: "600"
    },
    validForm: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
/* Lisää tarvittaessa mukautettuja tyylejä */
</style>
